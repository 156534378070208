import { TariffComponentRepoImpl } from "domain/repository/TariffCode/TariffComponentRepoImpl";
import { TariffComponentValueRepoImpl } from "domain/repository/TariffCode/TariffComponentValueRepoImpl";
import { useTariffComponentMaintenanceTracked } from "presentation/store/TariffComponent/TariffComponentMaintenanceProvider";
import { TariffComponentMaintenanceVM } from "presentation/viewModel/TariffComponent/TariffComponentMaintenanceVM";
import { useMemo } from "react";

export const useTariffComponentMaintenanceVM = () => {
    const [, setTariffComponentMaintenanceState] = useTariffComponentMaintenanceTracked();
    const tariffComponentMainVM = useMemo(() =>
        TariffComponentMaintenanceVM({
            dispatch: [setTariffComponentMaintenanceState],
            tariffComponentRepo: TariffComponentRepoImpl(),
            tariffComponentValueRepo: TariffComponentValueRepoImpl(),
        }), [setTariffComponentMaintenanceState])

    return tariffComponentMainVM
}

