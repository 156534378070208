import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { TariffComponentMaintenanceProvider } from "presentation/store/TariffComponent/TariffComponentMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { TariffComponentMasterView } from "presentation/view/section/TariffComponent/TariffComponentMasterView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const TariffComponentMaintenanceCont = () => {
    return <ANAInfoWrapper permission={Permission.TARIFF_COMPONENT_MAINTENANCE}>
        <MessageBarWrapper>
            <TariffComponentMaintenanceProvider>
                <GridStyles/>
                <TariffComponentMasterView/>
            </TariffComponentMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default TariffComponentMaintenanceCont;