export interface TariffComponentValueEntity {
    id: number | null,
    tariffCompId: number | null,
    tariffCompValueCode: string,
    tariffCompValueDesc: string | null,
    activeInd: string | null,

    tariffCompCode: string,
    tariffCompDesc: string | null,
    seq: number | null,

    dummyKey?: string | null,

    [key: string] : string | number | null | undefined
}

export const EMPTY_TARIFF_COMP_VALUE_ENTITY : TariffComponentValueEntity = {
    id: null,
    tariffCompId: null,
    tariffCompValueCode: '',
    tariffCompValueDesc: null,
    activeInd: "Y",
    tariffCompCode: "",
    tariffCompDesc: null,
    seq: null
}