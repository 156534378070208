export const tariffComponentMaintenanceRequiredFieldList: string[] = [
    'tariffComponent',
];

export const TariffComponentMaintenanceConstant = {
    CATEGORY: "Tariff",
    TITLE: "Tariff Component",
    SEQ: 'Seq',
    TARIFF_COMPONENT: "Tariff Component",
    TARIFF_COMPONENT_DESC: 'Tariff Component Desc',
    ACTIVE_IND: 'Active Ind.',
    TARIFF_COMPONENT_VALUE: "Tariff Component Value",
    TARIFF_COMPONENT_VALUE_DESC: 'Tariff Component Value Desc',
}
