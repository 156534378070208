import { EMPTY_MASTER_TARIFF_COMPONENTANY_MODEL } from "presentation/model/TariffComponent/TariffComponentMaintenanceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: TariffComponentMaintenanceProvider,
    useTracked: useTariffComponentMaintenanceTracked
} = createContainer(() => useState(EMPTY_MASTER_TARIFF_COMPONENTANY_MODEL), {concurrentMode: true});
export { TariffComponentMaintenanceProvider, useTariffComponentMaintenanceTracked };

