
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { EMPTY_TARIFF_COMPONENT_ENTITY, TariffComponentEntity } from "domain/entity/TariffCode/TariffComponentEntity";
import { EMPTY_TARIFF_COMP_VALUE_ENTITY, TariffComponentValueEntity } from "domain/entity/TariffCode/TariffComponentValueEntity";
import { TariffComponentRepository } from "domain/repository/TariffCode/TariffComponentRepo";
import { TariffComponentValueRepository } from "domain/repository/TariffCode/TariffComponentValueRepo";
import _ from "lodash";
import { createTariffComponentValidationSchema, createTariffComponentValueValidationSchema } from "presentation/constant/TariffComponent/TariffComponentMaintenanceValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { TariffComponentMaintenanceModel } from "presentation/model/TariffComponent/TariffComponentMaintenanceModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";


interface TariffComponentVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<TariffComponentMaintenanceModel>> | ((value: SetStateAction<TariffComponentMaintenanceModel>) => void),
    ],
    tariffComponentRepo: TariffComponentRepository,
    tariffComponentValueRepo: TariffComponentValueRepository,
}

export const TariffComponentMaintenanceVM = ({ dispatch, tariffComponentRepo, tariffComponentValueRepo }: TariffComponentVMProps) => {
    const [tariffComponentDispatch] = dispatch;



    const updateSelectedRows = async (rows: TariffComponentEntity[]) => {

        tariffComponentDispatch(prevState => {
            return {
                ...prevState,
                selectedRows: rows,
            }
        })
    }

    const onRowDoubleClick = (entity: TariffComponentEntity) => {
        tariffComponentDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: false,
                isShowDetail: true,
                currentSelectedRow: {
                    ...entity,
                },
                masterState: {
                    ...prevState.masterState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: false,
                    isSaveClicked: false,
                    isSliderOpen: true,
                    allFormState: {}
                },
                detailState: {
                    ...prevState.detailState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: false,
                    isSliderOpen: false,
                    isSelected: false,
                    allFormState: {}
                }
            }
        })
    }

    const onRowDrag = (rows: TariffComponentEntity[]) => {
        tariffComponentDispatch(prevState => {
            const prevRows = prevState.tableData;
            const toChangedRows = rows.map((e, index) => ({ ...e, tempSeq: prevRows[index].seq })).filter(e => e.tempSeq !== e.seq);
            return ({
                ...prevState,
                tableData: rows,
                updatedRows: toChangedRows,
            })
        })
    }

    const loadDropdownOption = async () => {

    }


    const onSearch = async () => {
        tariffComponentDispatch(prevState => {
            return {
                ...prevState,
                isBackMaster: false,
                selectedRows: [],
                tableData: [],
                currentSelectedRow: { ...EMPTY_TARIFF_COMPONENT_ENTITY }
            }
        })
        await tariffComponentRepo.getEntities().then((data) => {
            tariffComponentDispatch(prevState => {
                return {
                    ...prevState,
                    tableData: _.sortBy(data, ["seq"]),
                    selectedRows: [],
                }
            })
        }).catch((error) => {
            return [];
        })

    }

    const onAdd = () => {
        tariffComponentDispatch(prevState => ({
            ...prevState,
            currentSelectedRow: EMPTY_TARIFF_COMPONENT_ENTITY,
            masterState: {
                ...prevState.masterState,
                isTabularDataActive: false,
                isAdd: true,
                isRead: false,
                isEditable: false,
                isSaveClicked: false,
                isSliderOpen: true,
                allFormState: {},
                editingEntity: EMPTY_TARIFF_COMPONENT_ENTITY,
            },
            detailState: {
                ...prevState.detailState,
                isTabularDataActive: false,
                isAdd: false,
                isRead: true,
                isEditable: false,
                isSliderOpen: false,
                isSelected: false,
                allFormState: {}
            }
        }))
    }

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        if (fieldKey === 'tariffCompCode') {
            val = val.toUpperCase().replace(/\s+/g, '');
        }
        tariffComponentDispatch(prevState => {

            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        [fieldKey]: val,
                    },
                    allFormState: {
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        })
    }


    const onApply = async (updatedRows: TariffComponentEntity[]) => {

        return await tariffComponentRepo.onApply(updatedRows);
    }

    const onReset = async () => {
        tariffComponentDispatch(prevState => {
            let tempEntity: TariffComponentEntity = EMPTY_TARIFF_COMPONENT_ENTITY;
            if (!prevState.masterState.isAdd) {
                tempEntity = prevState.currentSelectedRow
            }

            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...tempEntity,
                    },
                }
            }
        })
    }

    const onTempSaveDetail = async (currentDetailEntity: TariffComponentValueEntity) => {
        const valResult = await Validation(createTariffComponentValueValidationSchema).ValidateFormOnly(currentDetailEntity);

        // let msg = 'Error when save data.'

        let validatedResult: { [x: string]: string } = {};
        if (valResult) {
            validatedResult = { ...validatedResult, ...valResult, mandatoryCheckFail: 'Please input the missing value.' };
            tariffComponentDispatch(prevState => {
                return {
                    ...prevState,
                    detailState: {
                        ...prevState.detailState,
                        allFormState: {
                            ...validatedResult
                        },
                    }
                }
            });
            return validatedResult;
        } else {
            return tariffComponentDispatch(prevState => {
                let oldDtls = prevState.detailState.detailList;
                let newDtls = oldDtls.filter(dtl => (dtl.id !== null && dtl.id !== currentDetailEntity.id)
                    || (dtl.id === null && dtl.dummyKey !== currentDetailEntity.dummyKey));
                newDtls.push(currentDetailEntity);

                return {
                    ...prevState,
                    detailState: {
                        ...prevState.detailState,
                        detailList: _.sortBy(newDtls, ["tariffCompValueCode"]),
                        selectedRows: [],
                        currentSelectItem: EMPTY_TARIFF_COMP_VALUE_ENTITY,
                        isShowDetailInfo: false,
                        isAdd: false,
                        isEditable: false,
                        isRead: true,
                        isSliderOpen: false,
                    }
                }
            })
        }
    }

    const onSave = async (currentEntity: TariffComponentEntity, isAdd: boolean) => {
        const valHdrResult = await Validation(createTariffComponentValidationSchema).ValidateFormOnly(currentEntity);

        if (valHdrResult) {
            let validatedTariffResult: { [x: string]: string } = {};
            if (valHdrResult) {
                validatedTariffResult = { ...validatedTariffResult, ...valHdrResult, mandatoryCheckFail: 'Please input the missing value.' };
            }

            tariffComponentDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedTariffResult
                        },

                    }
                }
            });

            const res: ResponseEntity = {
                code: "",
                success: false,
                msg: null,
                data: 'Please input the missing value.'
            }

            return res;
        } else {

            if (isAdd) {
                return await tariffComponentRepo.createEntity(currentEntity);
            } else {
                return await tariffComponentRepo.updateEntity(currentEntity);
            }

        }
    }

    const onClose = () => {
        tariffComponentDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: false,
                isBackMaster: true,
                selectedRows: [],
                currentSelectedRow: { ...EMPTY_TARIFF_COMPONENT_ENTITY },
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    isSliderOpen: false,
                    isTabularDataActive: true,
                    editingEntity: EMPTY_TARIFF_COMPONENT_ENTITY,
                },
                detailState: {
                    ...prevState.detailState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    isTabularDataActive: true,
                    detailList: [],
                    currentSelectItem: EMPTY_TARIFF_COMP_VALUE_ENTITY,
                    selectedRows: []
                }
            }
        });
    }

    const onSaveClicked = () => {
        tariffComponentDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }


    const onEdit = (currentEty: TariffComponentEntity) => {
        tariffComponentDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isRead: false,
                    isEditable: true,
                    editingEntity: {
                        ...currentEty,
                    }
                },
                detailState: {
                    ...prevState.detailState,
                    isRead: prevState.detailState.isSliderOpen ? false : true,
                    isEditable: false,
                    isSliderOpen: false,
                },
            }
        })
    }

    const onRadioChange = (e: any, fieldName: string) => {
        if (e.checked) {
            return tariffComponentDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        editingEntity: {
                            ...prevState.masterState.editingEntity,
                            [fieldName]: e.key,
                        },
                    }
                }

            })
        }
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        tariffComponentDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: checked ? "Y" : "N",
                }
            }
        }))
    }

    const onDetailCancel = () => {
        tariffComponentDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    currentSelectItem: EMPTY_TARIFF_COMP_VALUE_ENTITY,
                    editingEntity: EMPTY_TARIFF_COMP_VALUE_ENTITY,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    isSliderOpen: false,
                    allFormState: {}
                }
            }
        })
    }

    const onDetailSaveClicked = () => {
        tariffComponentDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onDetailFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        if (fieldKey === 'tariffCompValueCode') {
            val = val.toUpperCase().replace(/\s+/g, '');
        }

        tariffComponentDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    editingEntity: {
                        ...prevState.detailState.editingEntity,
                        [fieldKey]: val
                    },
                    allFormState: {
                        ...prevState.detailState.allFormState,
                        [fieldKey]: '',
                    },
                }
            }
        })
    }

    const updateSelectedDetailRows = async (rows: any[]) => {
        tariffComponentDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    currentSelectItem: rows[0],
                    selectedRows: rows,
                }
            }
        })
    }

    const onDetailRowClick = (row: TariffComponentValueEntity) => {
        tariffComponentDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    currentSelectItem: row,
                    editingEntity: row,
                    isSliderOpen: true,
                    isRead: false,
                    isEditable: true,
                }
            }
        });
    }

    const onDetailAdd = () => {
        tariffComponentDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    editingEntity: {
                        ...EMPTY_TARIFF_COMP_VALUE_ENTITY,
                        dummyKey: _.uniqueId(),
                    },
                    isAdd: true,
                    isSliderOpen: true,
                    isEditable: false,
                    isRead: false,
                    isSaveClicked: false,
                }
            }
        })
    }

    const onDetailDelete = (selectedRows: TariffComponentValueEntity[]) => {
        tariffComponentDispatch(prevState => {
            const rows = prevState.detailState.detailList;
            const retainRows = rows.filter(row => !selectedRows.find(delRow =>
                row.tariffCompId === delRow.tariffCompId && row.tariffCompValueId === delRow.tariffCompValueId));
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    detailList: retainRows ?? []
                }
            }
        })
    }

    const onSearchDetail = async (headerId: number) => {

        // tariffComponentDispatch(prevState => {
        //     return {
        //         ...prevState,
        //         detailState: {
        //             ...prevState.detailState,
        //             detailList: [],
        //             selectedRows: [],
        //         }
        //     }
        // })

        // tariffComponentValueRepo.getTariffComponentValuesByHeaderId(headerId).then((data) => {
        //     tariffComponentDispatch(prevState => {
        //         return {
        //             ...prevState,
        //             detailState: {
        //                 ...prevState.detailState,
        //                 detailList: data,
        //                 selectedRows: [],
        //                 currentSelectItem: EMPTY_TARIFF_COMP_VALUE_ENTITY,
        //                 editingEntity: EMPTY_TARIFF_COMP_VALUE_ENTITY,
        //             }
        //         }
        //     })
        // })
        tariffComponentDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    detailList: prevState.currentSelectedRow.componentValues ?? [],
                    selectedRows: [],
                    currentSelectItem: EMPTY_TARIFF_COMP_VALUE_ENTITY,
                    editingEntity: EMPTY_TARIFF_COMP_VALUE_ENTITY,
                }
            }
        })
    }

    const onDetailCheckboxChange = (checked: boolean, fieldName: string) => {
        tariffComponentDispatch(prevState => ({
            ...prevState,
            detailState: {
                ...prevState.detailState,
                editingEntity: {
                    ...prevState.detailState.editingEntity,
                    [fieldName]: checked ? "Y" : "N",
                }
            }
        }))
    }

    return {
        onHeaderFieldChange: onHeaderFieldChange,
        loadDropdownOption: loadDropdownOption,
        onRowDoubleClick: onRowDoubleClick,
        onRowDrag: onRowDrag,
        updateSelectedRows: updateSelectedRows,
        onSearch: onSearch,
        onAdd: onAdd,
        onEdit: onEdit,
        onSaveClicked: onSaveClicked,
        onReset: onReset,
        onSave: onSave,
        onClose: onClose,
        onApply: onApply,
        onTempSaveDetail: onTempSaveDetail,
        onRadioChange: onRadioChange,
        onCheckboxChange: onCheckboxChange,
        onDetailCancel: onDetailCancel,
        onDetailSaveClicked: onDetailSaveClicked,
        onDetailFieldChange: onDetailFieldChange,
        updateSelectedDetailRows: updateSelectedDetailRows,
        onDetailRowClick: onDetailRowClick,
        onDetailAdd: onDetailAdd,
        onDetailDelete: onDetailDelete,
        onSearchDetail: onSearchDetail,
        onDetailCheckboxChange: onDetailCheckboxChange,
    }
}