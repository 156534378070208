import { TariffComponentValueEntity } from "./TariffComponentValueEntity"

export interface TariffComponentEntity {
    id: number | null,
    seq: string | null,
    tariffCompCode: string,
    tariffCompDesc: string | null,
    componentValues: TariffComponentValueEntity[] | null,
    activeInd: "Y"|"N",

    [key: string] : string | number | TariffComponentValueEntity[] | null
}

export const EMPTY_TARIFF_COMPONENT_ENTITY : TariffComponentEntity = {
    id: null,
    seq: null,
    tariffCompCode: '',
    tariffCompDesc: null,
    componentValues: null,
    activeInd: "Y"
}